<template>
  <div class="news-views flex flex-col lg:flex-row">
    <NewsShow />
    <ListaNoticias title="Outras Notícias" />
  </div>
</template>

<script>
import NewsShow from "@/components/News/NewsShow";
import ListaNoticias from "../components/Home/ListaNoticias.vue";
export default {
  components: {
    NewsShow,
    ListaNoticias,
  },
};
</script>

<style lang="scss">
.news-views {
  max-width: 1440px;
  margin: 0 auto;
}
.noticia {
  max-width: 960px;
  background-color: rgba(255, 255, 255, 0.9);
  margin: 0 auto;
  padding: 2rem 1rem;
}
</style>
