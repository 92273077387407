<template>
  <div class="offices-view flex flex-col md:flex-row">
    <OfficeData class="w-full md:w-3/4" />
    <OfficeMenu class="w-full md:w-1/4" />
  </div>
  <OfficeGallery />
</template>

<script>
import OfficeMenu from "@/components/Offices/OfficeMenu.vue";
import OfficeData from "../components/Offices/OfficeData.vue";
import OfficeGallery from "@/components/Offices/OfficeGallery.vue";

export default {
  name: "OfficesView",
  components: {
    OfficeData,
    OfficeMenu,
    OfficeGallery,
  },
};
</script>

<style lang="scss">
.offices-view {
  max-width: 1440px;
  margin: 2rem auto;

  @media screen and (max-width: 1440px) {
    margin: 2rem 1rem;
  }
}
</style>
