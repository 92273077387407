<template>
  <div>
    <div class="noticia">
      <loading
        :active="loading"
        :can-cancel="false"
        :is-full-page="false"
      ></loading>
      <div class="noticia-content" v-if="Object.keys(noticia).length > 0">
        <div class="noticia-text">
          <h2 class="noticia-header">
            {{ noticia.titulo }}
            <!-- <div>{{ copyright }}</div> -->
          </h2>
          <h4 class="noticia__date">
            <span class="noticia__sec"> {{ noticia.secretaria }} </span>
            <span> | </span>
            {{ formatDate(noticia.data) }}
          </h4>
          <br />
          <div class="noticia-texto" v-html="noticia.conteudo"></div>
        </div>
        <div class="noticia-images" v-if="Array.isArray(noticia.images)">
          <img
            v-for="(image, index) in noticia.images"
            :key="index"
            :src="image"
            alt=""
            @click.prevent="showImage(image)"
          />
        </div>
      </div>
      <div class="share-network-list mt-4" v-if="url">
        <ShareNetwork
          v-for="network in networks"
          :network="network.network"
          :key="network.network"
          :url="url"
          :title="noticia.titulo"
          :description="noticia.titulo"
          hashtags="PrefeituraAnastacioMS"
          :style="{ backgroundColor: network.color }"
        >
          <i :class="network.icon"></i>
          <span>{{ network.name }}</span>
        </ShareNetwork>
      </div>
    </div>
    <vue-final-modal
      v-model="showModal"
      classes="modal-container"
      content-class="modal-content"
      :esc-to-close="true"
      @before-close="closeModal"
    >
      <span class="modal__close" @click="closeModal">
        <img :src="close" alt="" />
      </span>
      <div class="modal__content">
        <div class="image-container">
          <img :src="selectedImage" alt="" />
        </div>
      </div>
    </vue-final-modal>
  </div>
</template>

<script>
import { VueFinalModal } from "vue-final-modal";
import Loading from "vue3-loading-overlay";
import myMixin from "../../mixins";
const close = new URL("@/assets/icons/close-thick.png", import.meta.url).href;

export default {
  components: {
    VueFinalModal,
    Loading,
  },
  mixins: [myMixin],
  data() {
    return {
      noticia: {},
      loading: false,
      selectedImage: "",
      showModal: false,
      close,
      networks: [
        {
          network: "email",
          name: "Email",
          icon: "far fah fa-lg fa-envelope",
          color: "#333333",
        },
        {
          network: "facebook",
          name: "Facebook",
          icon: "fab fah fa-lg fa-facebook-f",
          color: "#1877f2",
        },
        {
          network: "telegram",
          name: "Telegram",
          icon: "fab fah fa-lg fa-telegram-plane",
          color: "#0088cc",
        },
        {
          network: "whatsapp",
          name: "Whatsapp",
          icon: "fab fah fa-lg fa-whatsapp",
          color: "#25d366",
        },
      ],
      host: `${window.location.protocol}//${window.location.hostname}`,
      url: null,
    };
  },
  methods: {
    async getNoticia(id) {
      this.loading = true;

      const res = await this.$axios.get(`/noticias/${id}`).catch((e) => {
        console.error("Notícia", e);
      });
      if (res.status === 200) {
        this.noticia = res.data;
        this.url = `${this.host}/noticia/${this.noticia.id}/${this.slugify(
          this.noticia.titulo
        )}`;
      }
      this.loading = false;

      this.$nextTick(() => {
        this.adicionarOnClick();
      });
    },
    closeModal() {
      this.selectedImage = "";
      this.showModal = false;
      this.isLoading = false;
    },
    showImage(image) {
      this.selectedImage = image;
      this.showModal = true;
    },
    adicionarOnClick() {
      const imagens = document.querySelectorAll(".noticia-texto img");
      imagens.forEach((imagem) => {
        imagem.onclick = () => {
          this.showImage(imagem.src);
        };
      });
    },
    slugify(text) {
      return text
        .toString()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .trim()
        .replace(/\s+/g, "-")
        .replace(/[^\w-]+/g, "")
        .replace(/--+/g, "-");
    },
  },
  mounted() {
    if (!this.$route.params.id) {
      this.$router.push("/");
    }
    this.getNoticia(this.$route.params.id);
  },
  beforeUnmount() {
    this.accessibleFontSize();
  },
};
</script>

<style lang="scss">
.noticia {
  // max-width: 960px;
  // margin: 2rem auto;
  color: #01326f;

  position: relative;
  & > .vld-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  // h2 > span,
  // h4 > span {
  //   color: #3ab34c;
  // }
  &__date,
  &-more {
    font-family: "Arboria Bold", sans-serif;
    text-transform: uppercase;
    font-size: var(--font-size--large);
    margin: 0;
  }

  &-header,
  &__date {
    padding-top: 1rem;
  }

  &-header {
    font-size: 1.8rem;
  }

  &__date {
    color: #686868;
  }

  &__sec {
    font-family: "Arboria Bold", sans-serif;
    text-transform: uppercase;
    font-size: var(--font-size--default);
    color: #01326f;
    margin: 0;
  }

  &-texto {
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    font-size: var(--font-size--default);
    strong {
      font-family: "Arboria Bold", sans-serif;
    }

    & img {
      display: inline-block;
      margin: 10px;
      max-width: 45%;
      cursor: zoom-in;

      @media screen and (max-width: 767px) {
        max-width: 90%;
      }
    }
  }

  &-content {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;

    // @media screen and (max-width: 768px) {
    //   flex-direction: column;
    // }
  }

  &-images {
    flex-wrap: wrap;
    flex: 1;
    display: flex;
    justify-content: center;
    & > img {
      margin: 10px;
      max-height: 150px;
      max-width: 100%;
      cursor: zoom-in;
      @media screen and (max-width: 767px) {
        max-height: 200px;
        max-width: 100%;
      }
    }
  }

  .access-icons {
    display: flex;
    justify-content: end;
    img {
      cursor: pointer;
      margin: 0 2px;
      height: 35px;
    }
  }

  .modal-content {
    width: auto;
    height: auto;
    max-width: 80%;
    max-height: 80%;
    overflow-y: hidden;
  }

  .image-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
.share-network-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1000px;
  margin: auto;
}

a[class^="share-network-"] {
  flex: none;
  color: #fff;
  background-color: #333;
  border-radius: 3px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 10px 10px 0;
  &:hover {
    color: #fff;
    text-decoration: none;
  }
}

a[class^="share-network-"] .fah {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 10px;
  flex: 0 1 auto;
}

a[class^="share-network-"] span {
  padding: 0 10px;
  flex: 1 1 0%;
  font-weight: 500;
}

.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Define modal styles */
.modal-content {
  max-width: 700px;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  .modal__close {
    width: 30px;
    margin: 0;
    cursor: pointer;
  }
}
.image-container {
  position: relative;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
