<template>
  <div
    class="lista-noticias m-4 p-2.5 border-solid border border-gray rounded-lg box-border"
  >
    <loading
      :active="loading"
      :can-cancel="false"
      :is-full-page="false"
    ></loading>
    <h3 class="text-xl font-bold text-regular-blue p-2.5">{{ title }}</h3>

    <ul class="w-full p-2.5" v-if="Object.keys(noticiasList).length > 0">
      <li
        v-for="(noticia, index) in noticiasList.data"
        :key="index"
        class="mb-2.5"
      >
        <span>{{ formatDate(noticia.data, "DD/MM/YYYY") }}</span>
        -
        <a
          class="text-regular-blue text-justify"
          :href="getPage(noticia.id, slugify(noticia.titulo))"
        >
          {{ noticia.titulo }}</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
import Loading from "vue3-loading-overlay";
import myMixin from "../../mixins";
export default {
  components: {
    Loading,
  },
  props: {
    title: {
      type: String,
      default: "Notícias",
    },
  },
  mixins: [myMixin],
  data() {
    return {
      loading: false,
      noticiasList: [],
    };
  },
  methods: {
    async getNoticias() {
      this.loading = true;
      const res = await this.$axios.get(`/noticias`).catch((e) => {
        console.error("Notícias", e);
      });
      if (res.status === 200) {
        this.noticiasList = res.data;
      }
      this.loading = false;
    },

    getPage(id, title) {
      return `${`/noticia/${id}/${title}`}`;
    },
  },
  mounted() {
    this.getNoticias();
  },
};
</script>

<style></style>
