<template>
  <div class="destaques">
    <loading
      :active="loading"
      :can-cancel="false"
      :is-full-page="false"
    ></loading>
    <div
      class="destaques-image-grid"
      v-if="Object.keys(destaqueList).length > 0"
    >
      <a
        v-for="(destaque, index) in destaqueList.data"
        :class="index === 0 && takeTwo"
        :key="index"
        :href="getPage(destaque.id, slugify(destaque.titulo))"
      >
        <img :src="destaque.capa" alt="" @error="placeholder" />
        <span class="textos">
          <span class="title-wrapper">
            <p class="title">{{ destaque.titulo }}</p>
          </span>
        </span>
      </a>
    </div>
  </div>
</template>

<script>
import Loading from "vue3-loading-overlay";
import myMixin from "../../mixins";

export default {
  components: {
    Loading,
  },
  mixins: [myMixin],
  data() {
    return {
      takeTwo: "destaques-image-grid-col-2 destaques-image-grid-row-2",
      loading: false,
      destaqueList: [],
    };
  },
  methods: {
    placeholder(e) {
      e.currentTarget.offsetWidth;
      e.target.src = require(`@/assets/no-image.jpg`);
    },
    async getDestaques() {
      this.loading = true;
      const res = await this.$axios.get(`/noticias/destaques`).catch((e) => {
        console.error("Notícias Destaque", e);
      });
      if (res.status === 200) {
        this.destaqueList = res;
      }
      this.loading = false;
    },

    getPage(id, title) {
      // this.$router.push(`${`/noticia/${id}/${title}`}`);
      return `${`/noticia/${id}/${title}`}`;
    },
    // handleShowDestaque(item) {
    //   this.getPage(item.id, this.slugify(item.title));
    // },
  },
  mounted() {
    this.getDestaques();
  },
};
</script>

<style lang="scss">
.destaques {
  max-width: 1440px;
  margin: 2rem auto 0;
  display: grid;
  position: relative;
  & > .vld-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .title {
    & > span {
      display: inline-block;
      vertical-align: middle;
      height: 36px;
    }
  }

  &-image-grid {
    --gap: 1rem;
    --num-cols: 4;
    --row-height: 300px;

    box-sizing: border-box;
    padding: var(--gap) 0;

    display: grid;
    grid-template-columns: repeat(var(--num-cols), 1fr);
    grid-auto-rows: var(--row-height);
    gap: var(--gap);

    & > a {
      position: relative;
      & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      & > span.textos {
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      }

      p {
        width: max-content;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;

        font-size: 16px;
        line-height: 20px;
        font-weight: bold;
        color: #ffffff;

        &.tag {
          font-family: "Arboria", sans-serif;
          background-color: rgba(31, 172, 70, 0.7);
          text-transform: uppercase;
          padding: 3px 7px;
        }

        &.title {
          font-family: "Arboria Bold", sans-serif;
          width: auto;
        }
      }

      .title-wrapper {
        display: block;
        background-color: rgba(0, 0, 0, 0.4);
        padding: 7px;
      }
    }

    &-col-2 {
      grid-column: span 2;
    }

    &-row-2 {
      grid-row: span 2;
    }
  }

  @media screen and (max-width: 1024px) {
    &-image-grid {
      --row-height: 200px;
    }
  }

  @media screen and (max-width: 768px) {
    &-image-grid {
      --num-cols: 2;
      --row-height: 250px;
    }
  }

  @media screen and (max-width: 425px) {
    &-image-grid {
      --num-cols: 1;
      --row-height: 300px;

      &-col-2 {
        grid-column: span 1;
      }

      &-row-2 {
        grid-row: span 1;
      }
    }
  }
}
</style>
