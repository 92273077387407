<template>
  <div class="photos m-4 p-2.5 w-900-or-60">
    <loading
      :active="loading"
      :can-cancel="false"
      :is-full-page="false"
    ></loading>
    <div v-if="!loading && Object.keys(gallery).length > 0" class="">
      <h3 class="text-xl font-bold text-regular-blue p-2.5">
        {{ gallery.titulo }}
      </h3>
      <div class="p-2.5">
        <p v-if="gallery.secretaria_name">
          <a :href="getSec(gallery.sec_id, slugify(gallery.secretaria_name))">{{
            gallery.secretaria_name
          }}</a>
        </p>
        <p>
          <span>{{ formatDate(gallery.data, "DD/MM/YYYY") }}</span>
        </p>
      </div>
    </div>

    <ul
      class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4"
      v-if="Object.keys(photosList).length > 0"
    >
      <li
        v-for="(data, index) in photosList"
        :key="index"
        class="p-2.5 cursor-zoom-in"
        @click.prevent="showImage(data.images)"
      >
        <img
          class="object-cover w-auto sm:w-52 h-auto"
          :src="data.images"
          alt=""
        />
      </li>
    </ul>

    <vue-final-modal
      v-model="showModal"
      classes="modal-container"
      content-class="modal-content"
      :esc-to-close="true"
      @before-close="closeModal"
    >
      <span class="modal__close" @click="closeModal">
        <img :src="close" alt="" />
      </span>
      <div class="modal__content">
        <div class="image-container">
          <img :src="selectedImage" alt="" />
        </div>
      </div>
    </vue-final-modal>
  </div>
</template>

<script>
import { VueFinalModal } from "vue-final-modal";
import Loading from "vue3-loading-overlay";
import myMixin from "../../mixins";
const close = new URL("@/assets/icons/close-thick.png", import.meta.url).href;
export default {
  components: {
    VueFinalModal,
    Loading,
  },
  mixins: [myMixin],
  data() {
    return {
      loading: false,
      title: "title",
      photosList: [],
      currentPage: 1,
      close,
      showModal: false,
      selectedImage: "",
      gallery: [],
    };
  },
  methods: {
    async getGallery(id) {
      const res = await this.$axios.get(`/v1/galerias/${id}`).catch((e) => {
        console.error("Gallery", e);
      });
      if (res.status === 200) {
        this.gallery = res.data;
        // this.currentPage = res.data.current_page;
      }
    },
    async getPhotos(id) {
      this.loading = true;
      const res = await this.$axios
        .get(`/v1/galerias/images/${id}`)
        .catch((e) => {
          console.error("Photos", e);
        });
      if (res.status === 200) {
        this.photosList = res.data;
        // this.currentPage = res.data.current_page;
      }
      this.loading = false;
    },
    closeModal() {
      this.selectedImage = "";
      this.showModal = false;
      this.isLoading = false;
    },
    showImage(image) {
      this.selectedImage = image;
      this.showModal = true;
    },

    handleChangePage(pg) {
      if (this.currentPage !== pg) {
        this.$router.push(`/galerias/${pg}`);
        // this.getNoticias(pg);
      }
    },
    getSec(id, title) {
      return `${`/secretarias/${id}/${title}`}`;
    },

    getPage(id, title) {
      return `${`/galeria/${id}/${title}`}`;
    },
  },
  mounted() {
    this.getPhotos(this.$route.params.id);
    this.getGallery(this.$route.params.id);
  },
};
</script>

<style lang="scss">
.photos {
  position: relative;
  & > .vld-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  /* Define modal styles */
  .modal-content {
    max-width: 700px;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

    .modal__close {
      width: 30px;
      margin: 0;
      cursor: pointer;
    }
  }
  .image-container {
    position: relative;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .cursor-zoom-in {
    cursor: zoom-in;
  }
}
</style>
