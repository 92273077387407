<template>
  <div class="destaques">
    <loading
      :active="loading"
      :can-cancel="false"
      :is-full-page="false"
    ></loading>
    <Carousel
      v-bind="settings"
      :breakpoints="breakpoints"
      :wrap-around="true"
      :v-if="destaqueList.length > 0"
    >
      <template #slides>
        <Slide v-for="(destaque, index) in destaqueList.data" :key="index">
          <div class="carousel__item">
            <a
              :key="index"
              :href="getPage(destaque.id, slugify(destaque.titulo))"
            >
              <img
                class="w-full p-1 object-cover carousel-image"
                :src="destaque.fotos"
                alt=""
                @error="placeholder"
              />
              <span
                class="absolute bottom-5 h-25 left-3 right-3 flex items-center justify-center text-center bg-white bg-opacity-50 rounded"
              >
                <span class="">
                  <p class="title">{{ destaque.titulo }}</p>
                </span>
              </span>
            </a>
          </div>
        </Slide>
      </template>
      <template #addons="{ slidesCount }">
        <Navigation v-if="slidesCount > 1" />
      </template>
    </Carousel>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Navigation, Slide } from "vue3-carousel";
import Loading from "vue3-loading-overlay";
import myMixin from "../../mixins";
import "vue3-carousel/dist/carousel.css";

export default defineComponent({
  components: {
    Loading,
    Carousel,
    Slide,
    Navigation,
  },
  mixins: [myMixin],
  data() {
    return {
      takeTwo: "destaques-image-grid-col-2 destaques-image-grid-row-2",
      loading: false,
      destaqueList: [],
      settings: {
        itemsToShow: 1.5,
        snapAlign: "center",
      },
      breakpoints: {
        // 700px and up
        625: {
          itemsToShow: 2.5,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 3.5,
          snapAlign: "start",
        },
      },
    };
  },
  methods: {
    placeholder(e) {
      e.currentTarget.offsetWidth;
      e.target.src = require(`@/assets/no-image.jpg`);
    },
    async getGallery(id) {
      this.loading = true;
      const res = await this.$axios
        .get(`/v1/galerias?sec_id=${id}`)
        .catch((e) => {
          console.error("Notícias Destaque", e);
        });
      if (res && res.status === 200) {
        this.destaqueList = res.data;
      }
      this.loading = false;
    },

    getPage(id, title) {
      // this.$router.push(`${`/noticia/${id}/${title}`}`);
      return `${`/galeria/${id}/${title}`}`;
    },
    // handleShowDestaque(item) {
    //   this.getPage(item.id, this.slugify(item.title));
    // },
  },
  mounted() {
    this.getGallery(this.$route.params.id);
  },
});
</script>

<style lang="scss">
.destaques {
  max-width: 1440px;
  margin: 2rem auto 0;
  display: grid;
  position: relative;
  & > .vld-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .carousel__prev,
  .carousel__next {
    background-color: #659cc8;
    border-radius: 50%;
    height: 40px;
    width: 40px;
  }

  .carousel-image {
    height: 300px;

    @media screen and (max-width: 768px) {
      height: 250px;
    }
  }
}
</style>
