<template>
  <div class="photos-view flex flex-col lg:flex-row">
    <PhotosShow />
  </div>
</template>

<script>
import PhotosShow from "../components/Gallery/PhotosShow.vue";
export default {
  components: {
    PhotosShow,
  },
};
</script>

<style lang="scss">
.photos-view {
  max-width: 1440px;
  margin: 0 auto;
  justify-content: center;
}
/* .noticia {
  max-width: 960px;
  background-color: rgba(255, 255, 255, 0.9);
  margin: 0 auto;
  padding: 2rem 1rem;
} */
</style>
