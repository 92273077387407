<template>
  <div class="noticias-destaque flex justify-evenly flex-col lg:flex-row">
    <ListaNoticias />
    <CardNoticias />
  </div>
</template>

<script>
import CardNoticias from "./CardNoticias.vue";
import ListaNoticias from "./ListaNoticias.vue";
export default {
  components: {
    ListaNoticias,
    CardNoticias,
  },
};
</script>

<style lang="scss">
.noticias-destaque {
  max-width: 1440px;
  margin: 2rem auto 0;
  display: flex;
  justify-content: space-evenly;
}
</style>
